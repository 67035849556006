import React from 'react';
import PropTypes from 'prop-types';
import { Card, Metric } from "@tremor/react";
import RatingDonut from '../shared/RatingDonut';
import { formatMinutes } from "../../../datahooks/metadata/DataUtils";
import { Typography } from "@material-tailwind/react";

const StaticCodeMetric = ({ data }) => {

  const renderMetricValue = () => {
    switch (data.type) {
      case 'INT':
        return <Metric>{Number(data.value).toLocaleString()}</Metric>;
      case 'RATING':
        return <RatingDonut className="w-1/2" rating={Number(data.value)}  />;
      case 'WORK_DUR':
        return <Metric>{formatMinutes(data.value)}</Metric>;
      default:
        return <Metric>{data.value}</Metric>;
    }
  };
  
  let decorationColor;

  if (data.type === 'RATING') {
      decorationColor = data.bestValue === data.value ? "green" : "pink";
  } else {
      decorationColor = (data.bestValue === data.value) ? "green" : "pink";
  }

  return (
    <Card className="flex w-full h-full flex-col" decoration="top" decorationColor={decorationColor}>
      <Typography variant='h3'>{data.name}</Typography>
      {renderMetricValue()}
    </Card>
  );
};

StaticCodeMetric.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bestValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  })
};

export default StaticCodeMetric;
