import React from 'react';
import PropTypes from 'prop-types';
import { Card, Metric, Grid, BarChart } from '@tremor/react';
import { Typography, Chip } from '@material-tailwind/react';

import PerformanceTransactions from './PerformanceTransactions';

import LoadingIcon from '../../navigation/LoadingIcon';
import PerformanceHistogram from './PerformanceHistogram';
import PerformanceLineChart from './PerformanceLineChart';
import ErrorList from './ErrorList';
import PopPerformance from '../shared/popupExplanations/PopPerformance';

import { MdOutlineTimer } from 'react-icons/md';
import { getTimeDifference } from '../../../datahooks/metadata/DataUtils';

import PerformanceTrendChart from '../shared/PerformanceTrendChart';

export default function PerformancePage({ resultData, projectId }) {
    const performanceData = resultData.result_json;

    const decorationColor =  (performanceData.totals.errorCount === 0 ? "green" : "pink");
    const errorPercentageKeys = Object.keys(performanceData.errorPercentages).filter(key => key !== "name");

    return (
        <div className="flex flex-col w-full">
            <div className='flex flex-col md:flex-row md:items-center mb-5 pb-2 border-b border-gray-400 gap-2'>
                <div className="w-full mb-1 flex flex-row items-center">
                    <Typography variant="h2" className="text-blue mr-1">Performance</Typography>
                    <PopPerformance page size={25} />
                </div>
                <div className="flex flex-row gap-2">
                    <Chip className='flex-1' color="blue" value={getTimeDifference(resultData?.creation, resultData.last_update)} variant="outlined" icon={<MdOutlineTimer size={20}/>} />
                    <Chip className='flex-1' color="blue" value={resultData?.env} />
                </div>
            </div>

            <PerformanceTrendChart projectId={projectId} />

            <Grid numItemsMd={2} numItemsLg={3} className="gap-6 mb-5">
                <div className="flex flex-col gap-4 ">
                    {performanceData?.totals?.transactionsCount ?  (
                        <Card className="flex w-full h-full flex-col">
                            <Typography variant="h3">Total Scenarios</Typography>
                            <Metric>{performanceData.totals.transactionsCount.toLocaleString()}</Metric>
                        </Card>
                    ) : (
                    <Card className="flex flex-col justify-center items-center h-full">
                        <LoadingIcon showText />
                    </Card>
                    )}
                </div>

                <div className="flex flex-col gap-4 ">
                    {performanceData?.totals?.sampleCount ?  (
                        <Card className="flex w-full h-full flex-col">
                        <Typography variant="h3">Total Users</Typography>
                        <Metric>{performanceData.totals.sampleCount.toLocaleString()}</Metric>
                        </Card>
                    ) : (
                    <Card className="flex flex-col justify-center items-center h-full">
                        <LoadingIcon showText />
                    </Card>
                    )}
                </div>

                <div className="flex flex-col gap-4 ">
                    {performanceData?.totals?.sampleCount ?  (
                        <Card className="flex w-full h-full flex-col" decoration="top" decorationColor={decorationColor}>
                        <Typography variant="h3">Total Errors</Typography>
                        <Metric>{performanceData.totals.errorCount.toLocaleString()}</Metric>
                        </Card>
                    ) : (
                    <Card className="flex flex-col justify-center items-center h-full">
                        <LoadingIcon showText />
                    </Card>
                    )}
                </div>

                
            </Grid>

            <PerformanceHistogram theData={performanceData.responseTimeDistrib} theIndex={'Range'} title={'Response Time Distribution'} className="w-full" />

            <PerformanceLineChart theData={performanceData.transactionsPerSecond} theIndex="minute" title="Transactions Per Second" className="w-full" />

            {performanceData?.totals?.errorCount > 0 && 
                <>
                    <Card>
                        <Typography variant="h3">Error Percentage by Scenario</Typography>
                        <BarChart
                            className="mt-6"
                            data={[performanceData.errorPercentages]}
                            index="name"
                            colors={['blue', 'yellow', 'pink', 'orange', 'green', 'cyan',  'gray']}
                            valueFormatter={(value) => value.toString()}
                            categories={errorPercentageKeys}
                            yAxisWidth={48}
                            showAnimation
                        />
                    </Card>

                    <ErrorList 
                        title="Top Errors"
                        topErrors={performanceData?.top5Errors} 
                        totalSamples={performanceData?.totals?.sampleCount}
                        className="w-full" />
                </>
            }

            <PerformanceTransactions title="All Scenarios" transactions={performanceData.transactions} className="w-full" />

        </div>
    );
}

PerformancePage.propTypes = {
    resultData: PropTypes.object,
    projectId: PropTypes.number,
};