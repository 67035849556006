import React from 'react';
import { HiHome, HiFolder } from 'react-icons/hi';
import { List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import TextPlaceholder from "../general/TextPlaceholder";

import useProjects from '../../datahooks/useProjects';

/**
 * This component is used to generate the links for the menu.
 * The links are loaded from the projects file using the useProjects hook.
 * This allows us to include the same links in the drawer and sidebar component,
 * drawer for mobile, sidebar for desktop.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.doLogout - Function to handle logout events.
 * @returns {JSX.Element} The rendered list of menu links.
 */
const MenuLinks = () => {
  const { projects, isLoading } = useProjects();  //Load list of projects from useProjects hook

  return (
    <List data-testid='menulinks' className='min-w-full p-1'>
        <Link to={"/home"}>
            <ListItem className='text-blue-500'>
                <ListItemPrefix><HiHome className="h-5 w-5" /></ListItemPrefix>
                <Typography variant='paragraph' color='blue'>Home</Typography>
            </ListItem>
        </Link>
        <hr className="my-2 border-blue-gray-100" />
        {isLoading || !projects ? (
            <TextPlaceholder lines={5} random={false}  />
          ) : (
            projects.map(project => (
              <Link key={project.short_name} to={`/projects/${project.projectId}`}>
                  <ListItem className='text-blue-500'>
                  <ListItemPrefix>
                      <HiFolder className="h-5 w-5" />
                  </ListItemPrefix>
                  <Typography variant='paragraph' color='blue'>{project.short_name}</Typography>
                  </ListItem>
              </Link>
            ))
          )
        }
    </List>
  );
}

export default MenuLinks;
