import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import useProjects from "../datahooks/useProjects";

import AuthenticatedLayout from "../layouts/AuthenticatedLayout";

import AvatarLogo from "../components/general/AvatarLogo";
import TextPlaceholder from "../components/general/TextPlaceholder";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel, Typography } from "@material-tailwind/react";
import { Select, SelectItem, Card } from "@tremor/react";

import { featureDetails } from "../datahooks/metadata/DataUtils";
import TestTypeTabPanel from "../components/cards/TestTypeTabPanel";
import OverviewTabPanel from "../components/cards/overview/OverviewTabPanel";

import { CARD_TYPES, ErrorCard } from "../components/cards/ErrorCard";

export default function Project() {
    const [activeTab, setActiveTab] = useState("overview");
    const [releaseId, setReleaseId] = useState('');
    const [project, setProject] = useState(null);

    const { projectId } = useParams();
    const { projects, isLoading: isLoadingProjects } = useProjects();

    useEffect(() => {
        if (!isLoadingProjects) {
            const newProject = projects.find(p => p.projectId.toString() === projectId);
            
            if (newProject) {
                setProject(newProject);
                if (newProject.releases.length > 0) {
                    setReleaseId(newProject.releases[0].release_id.toString());
                } else {
                    console.log('No releases found');
                    setReleaseId('');
                }
            }
        }
    }, [projects, projectId]); //Either project data loads or project is changed
    
    const changeTab = (tabName) => {
        setActiveTab(tabName);
    }

    const doChangeRelease = (newVal) => {
        setReleaseId(newVal.toString());
    };

    let tabHeaders = [];
    let tabPanels = [];
    let release = project?.releases.find(r => r.release_id.toString() === releaseId);
    
    if (project && releaseId) {
        
        tabHeaders = [
            <Tab key="overview" value="overview" className="z-0">
                Overview
            </Tab>,
            ...(project.testTypeIds.map(testTypeId => (
                <Tab key={testTypeId} value={testTypeId.toString()} className="z-0">
                    {featureDetails[testTypeId].title}
                </Tab>
            )))
        ];

        tabPanels = [
            <TabPanel key="overview" value="overview">
                <OverviewTabPanel 
                    releaseId={releaseId} 
                    title={project.title} 
                    intro={project.intro_text}
                    releaseTitle={release.presentation_name}
                    releaseStatus={release.release_status}
                    releasedIntoTest={project.released_into_test}
                    releaseStartDate={release.creation_date}
                    releaseLastDate={release.last_update}
                    targetToLive={project.target_to_live}
                    doChangeTab={changeTab}
                />
            </TabPanel>,
            ...(project.testTypeIds.map(testTypeId => (
                <TabPanel key={testTypeId} value={testTypeId.toString()}>
                    <TestTypeTabPanel 
                        projectId={project?.projectId}
                        releaseId={releaseId} 
                        testTypeId={testTypeId} 
                        key={`${releaseId}_${testTypeId}`} />
                </TabPanel>
            )))
        ];
    }

    return (

        <AuthenticatedLayout>
            <div className="mt-1 p-3 sm:p-5 mx-auto bg-transparent">
                <div className="grid grid-cols-[auto_1fr_auto] items-center border-b-2 p-3 mb-2">
                    {
                        project?.title ? (
                            <>
                                <AvatarLogo 
                                    src={project.logo_location} 
                                    alt={`${project.short_name} logo`}
                                    size="lg"
                                    displayType="logo"
                                />
                                <Typography variant="h2" className="ml-2 md:ml-5 md:mr-5 mr-2 text-blue-500 ">
                                    {project.title}
                                </Typography>
                            </>
                        ) : (
                            <TextPlaceholder lines={1} width="w-1/2" />
                        )
                    }
                    <div>
                        {project && project.releases.length > 0 ? (
                            <Select
                              className='mt-Select-button z-22'
                                label="Build"
                                value={releaseId}
                                onChange={(newVal) => doChangeRelease(newVal)}
                            >
                                {project.releases.map((release) => (
                                    <SelectItem className='mt-Select-option w-full' key={release.version_id} value={release.release_id.toString()}>
                                        {release.presentation_name}
                                    </SelectItem>
                                ))}
                            </Select>
                        ) : (
                            <TextPlaceholder lines={1} width="w-1/2" /> // Placeholder until projects are loaded
                        )}
                    </div>
                </div>

                {project?.releases?.length === 0 ? (
                    <Card>
                        <ErrorCard cardType={CARD_TYPES.EMPTY} />
                        <Typography variant="h3" className="w-full mt-5 text-center" >No releases found.</Typography>
                        <Typography variant="paragraph" className="w-full text-center" >This could be because the project is new or because no releases have been made in the past 30 days.</Typography>

                    </Card>
                ) : 
                    <Tabs value={activeTab} onChange={setActiveTab}>
                        <TabsHeader>{tabHeaders}</TabsHeader>
                        <TabsBody>{tabPanels}</TabsBody>
                    </Tabs>    
                }

            </div>
        </AuthenticatedLayout>
    );
}