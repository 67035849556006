import { COLOURS } from '../../config';

const chartsConfig = [
  {
    name: 'Response Times',
    id: 'response_times',
    fields: ['meanResTime', 'maxResTime', 'minResTime'],
    readableNames: {
      meanResTime: 'Average',
      maxResTime: 'Max',
      minResTime: 'Min'
    },
    fieldColours: {
      meanResTime: COLOURS.brand,
      maxResTime: COLOURS.fail,
      minResTime: COLOURS.pass
    },
    label: 'Response Time (ms)',
    formatter: (value) => `${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}ms` // Thousand separator, no decimal places
  },
  {
    name: 'Response Time Percentiles',
    fields: ['pct1ResTime', 'pct2ResTime', 'pct3ResTime'],
    id: 'response_time_percentiles',
    readableNames: {
      pct1ResTime: '90th Percentile',
      pct2ResTime: '95th Percentile',
      pct3ResTime: '99th Percentile'
    },
    fieldColours: {
      pct1ResTime: COLOURS.pass,
      pct2ResTime: COLOURS.brand,
      pct3ResTime: COLOURS.fail
    },
    label: 'Percentile Response Time (ms)',
    formatter: (value) => `${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })}ms` // Thousand separator, up to 1 decimal places
  },
  {
    name: 'Throughput',
    id: 'trans_per_second',
    fields: ['throughput'],
    readableNames: {
      throughput: 'Throughput'
    },
    fieldColours: {
      throughput: COLOURS.brand
    },
    label: 'Throughput',
    formatter: (value) => `${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}tps` // Thousand separator, up to 1 decimal places
  },
  {
    name: 'Total vs Failed Requests',
    id: 'total_vs_failed',
    fields: ['errorCount', 'sampleCount'],
    readableNames: {
      errorCount: 'Failed',
      sampleCount: 'Total'
    },
    fieldColours: {
      errorCount: COLOURS.fail,
      sampleCount: COLOURS.brand
    },
    label: 'Count',
    formatter: (value) => parseInt(value, 10).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) // Thousand separator, up to 1 decimal places
  }
];

export default chartsConfig;