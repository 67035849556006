import React from 'react';
import PropTypes from 'prop-types';
import { Button, Select, Option } from '@material-tailwind/react';

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  onItemsPerPageChange,
  showItemsPerPageDropdown = true,
}) => {
  const maxPageButtons = 5; // Maximum number of page buttons to display

  // Calculate start and end page numbers for pagination display
  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

  // Adjust start and end pages if the total number of pages is less than maxPageButtons
  if (endPage - startPage + 1 < maxPageButtons && totalPages >= maxPageButtons) {
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex flex-wrap items-center m-8 space-y-4 sm:space-y-0">
      <div className="flex flex-grow flex-wrap justify-center items-center space-x-2">
        <Button
          variant="text"
          color="blue"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          Previous
        </Button>

        {startPage > 1 && (
          <>
            <Button variant="text" color="blue" onClick={() => onPageChange(1)}>1</Button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}

        {pageNumbers.map((number) => (
          <Button
            key={number}
            variant={currentPage === number ? "filled" : "text"}
            color="blue"
            onClick={() => onPageChange(number)}
          >
            {number}
          </Button>
        ))}

        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <Button variant="text" color="blue" onClick={() => onPageChange(totalPages)}>{totalPages}</Button>
          </>
        )}

        <Button
          variant="text"
          disabled={currentPage === totalPages}
          color="blue"
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
        </Button>
      </div>

      {showItemsPerPageDropdown && (
        <div className="ml-auto w-48">
          <Select
            className='mt-Select-button'
            value={String(itemsPerPage)}
            onChange={(value) => onItemsPerPageChange(parseInt(value, 10))}
          >
            <Option className='mt-Select-option' value="10">10 per page</Option>
            <Option className='mt-Select-option' value="25">25 per page</Option>
            <Option className='mt-Select-option' value="50">50 per page</Option>
            <Option className='mt-Select-option' value="100">100 per page</Option>
          </Select>
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired,
  showItemsPerPageDropdown: PropTypes.bool,
};

export default Pagination;