import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { FaArrowUp, FaArrowDown, FaMinus } from 'react-icons/fa';
import { Card } from "@tremor/react";
import { subDays, parseISO, isAfter } from 'date-fns';
import { ErrorCard, CARD_TYPES } from "../ErrorCard";
import { Select, Option, Typography } from "@material-tailwind/react";

import { calculateEMA, formatTooltipValue, getGradient, SCA_METRICS, PERFORMANCE_METRICS } from '../../../components/cards/shared/TrendUtils'; // Import the utility functions
import { COLOURS } from '../../../config';
import { ResponsiveContainer, ComposedChart, LineChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from "recharts";
import useProjectTrends from "../../../datahooks/useProjectTrends";
import LoadingIcon from "../../navigation/LoadingIcon";
import AlertManager from "../../general/AlertManager";

// Component to display the trend icon
export const TrendIcon = ({ overallTrend = 0, positiveDirection = 'up', testTypeId}) => {
  const adjustedDirection = [1, 4].includes(testTypeId) ? positiveDirection : 'up';  // Default to 'up' for pass/fail metrics
  const trendDirection = Math.sign(overallTrend);  // Convert value into 1, 0, or -1
  
  switch(trendDirection) {
    case 1:
      return <FaArrowUp data-testid="trendIcon" size={40} className={adjustedDirection === 'up' ? 'text-green-500' : 'text-pink-500'} />;
    case -1:
      return <FaArrowDown data-testid="trendIcon" size={40} className={adjustedDirection === 'up' ? 'text-pink-500' : 'text-green-500'}  />;
    case 0:
    default:
      return <FaMinus data-testid="trendIcon" size={40} className="text-orange-500" />;
  }
};

// The main TrendChart component
const TrendChart = ({ projectId, testTypeId, metricType="percentage", barKeys, chartType = 'bar'  }) => {

  const [ daysFilter, setDaysFilter] = useState(30); // Default filter to 30 days
  const [selectedMetricIndex, setSelectedMetricIndex] = useState(0);
  
  let metrics = null;
  if (testTypeId === 1) {
    metrics = SCA_METRICS;
  } else if (testTypeId === 4) {
    metrics = PERFORMANCE_METRICS;
  }

  const [ selectedMetric, setSelectedMetric] = useState(metrics ? metrics[0].metric : null);
  const [ metricPositiveDirection, setMetricPositiveDirection] = useState(metrics ? metrics[0].goodDir : 'up'); // Default direction for positive trend
  const { projectTrend, isLoading, isError } = useProjectTrends(testTypeId, projectId, selectedMetric);
  const [filteredData, setFilteredData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // State to manage error messages
  
  // Handle filtering of data by days and prepare the data
  useEffect(() => {
    if (projectTrend?.length > 0) {
      const currentDate = new Date();
      const newFilteredData = projectTrend.filter((item) => {
        const itemDate = parseISO(item.release_date);
        return isAfter(itemDate, subDays(currentDate, daysFilter));
      }).map((trend) => {
        let passedValue = trend.passed;
        
        // Convert technical_debt from minutes to hours
        if (selectedMetric === 'technical_debt') {
          passedValue = passedValue / 60;
        }

        return {
          ...trend, // Keep other data unchanged
          passed: passedValue, // Update passed value if needed
          trend: passedValue, // Initial trend value
        };
      });
      
      // After filtering, calculate the EMA for the trend
      const emaFilteredData = calculateEMA(newFilteredData, 'passed');
      setFilteredData(emaFilteredData);
    }
  }, [projectTrend, daysFilter, selectedMetric]);

  useEffect(() => {
    if ([1, 4].includes(testTypeId) && barKeys.length > 0 && metrics[selectedMetricIndex]) {
      // Only set the bar name if the testTypeId is 1 (SCA) or 4 (Performance)
      barKeys[0].name = metrics[selectedMetricIndex].label;
    }
  }, [selectedMetricIndex, barKeys, metrics, testTypeId]);  // Ensure testTypeId is included in dependencies
  
  const onSelectMetric = (index) => {
    const selectedMetricObject = metrics[index];
    setMetricPositiveDirection(selectedMetricObject.goodDir);
    setSelectedMetric(selectedMetricObject.metric);
    setSelectedMetricIndex(index);
  };

  // Calculate the Exponential Moving Average (EMA) for trend smoothing
  const overallTrend = Number(filteredData?.length > 0 ? filteredData[filteredData.length - 1].trend - filteredData[0].trend : 0);

  // Handle error state
  useEffect(() => {
    if (isError) {
      setErrorMessage("Error fetching data.");
    }
  }, [isError]);

  // If there's an error, only render the AlertManager
  if (isError) {
    return <AlertManager className="m-2" errorMessage={errorMessage} setErrorMessage={setErrorMessage} />;
  }

  if (!isLoading && filteredData.length === 0 && daysFilter === 30) {
    return null;
  }

  return (
    <Card className="mb-5">
      
      <div className="flex justify-between items-center">

        <Typography variant='h2' className="flex">Recent Results</Typography>
        <div className="flex items-center space-x-2">

          <Select
            className='mt-Select-button'
            label="Select Days"
            value={String(daysFilter)}
            onChange={(value) => setDaysFilter(Number(value))} // Convert to number for daysFilter
            data-testid='trend-days-filter'
          >
            <Option className='mt-Select-option' data-testid='trend-days-filter-option' value="7">Last 7 Days</Option>
            <Option className='mt-Select-option' data-testid='trend-days-filter-option' value="15">Last 15 Days</Option>
            <Option className='mt-Select-option' data-testid='trend-days-filter-option' value="30">Last 30 Days</Option>
          </Select>

          {[1,4].includes(testTypeId) &&
              <Select
                className="mt-Select-button"
                label="Select Metric"
                value={String(selectedMetricIndex)}  // Convert index to string
                onChange={(value) => onSelectMetric(Number(value))}  // Convert back to number in onSelectMetric
              >
                {metrics.map((metricObj, index) => (
                  <Option className="mt-Select-option" key={metricObj.metric} value={String(index)}>
                    {metricObj.label}
                  </Option>
                ))}
              </Select>
          }

          <TrendIcon overallTrend={overallTrend} positiveDirection={metricPositiveDirection} testTypeId={testTypeId} />
        </div>
      </div>

      {isLoading && (
        <div className="w-full h-72 flex justify-center items-center">
          <LoadingIcon showText />
        </div>
      )}

      {!isLoading && filteredData.length === 0 ? (
          <div className="w-full h-72 flex flex-col justify-center items-center">
            <ErrorCard cardType={CARD_TYPES.EMPTY} />
            <Typography variant='h6'>No releases available for the selected period.</Typography>
          </div>
        ) : (
        <div className="w-full h-72">
          <ResponsiveContainer data-testid='trendchart'>

            {chartType === 'bar' && (
              <ComposedChart
                data={filteredData}
                margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
              >
                <defs>
                  {/* Gradient for Passed Tests */}
                  <linearGradient id="passGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={COLOURS.pass} stopOpacity={1} />
                    <stop offset="95%" stopColor={COLOURS.pass} stopOpacity={0.7} />
                  </linearGradient>

                  {/* Gradient for Failed Tests */}
                  <linearGradient id="failGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={COLOURS.fail} stopOpacity={1} />
                    <stop offset="95%" stopColor={COLOURS.fail} stopOpacity={0.7} />
                  </linearGradient>
                </defs>

                <CartesianGrid stroke="#e6e6e6" vertical={false} />

                <Tooltip
                  formatter={(value, name) => formatTooltipValue(value, metricType, selectedMetric, name)}
                />

                <Legend className="mb-4" verticalAlign="top" align="center" height={50} />

                {/* Use the barKeys passed from the parent */}
                {barKeys.map((bar) => (
                  <Bar
                    key={bar.dataKey}
                    dataKey={bar.dataKey}
                    fill={getGradient(metricType, bar.dataKey)}
                    yAxisId="left"
                    stackId="a"
                    legendType="circle"
                    name={bar.name}
                  />
                ))}

                <Line
                  dot={false}
                  strokeWidth={3}
                  strokeLinecap="round"
                  dataKey="trend"
                  stroke={COLOURS.brand}
                  yAxisId="left"
                  legendType="circle"
                  name="Trend Line"
                />

                <YAxis
                  tickLine={false}
                  yAxisId="left"
                  axisLine={{ stroke: "#999" }}
                  unit={metricType === 'percentage' ? '%' : ''}
                  domain={[0, 'auto']}
                  tickCount={5}
                />

                <XAxis
                  dataKey="name"
                  tickLine={true}
                  axisLine={{ stroke: "#999" }}
                />
              </ComposedChart>
            )}           
            {chartType === 'line' && (
              <LineChart data={filteredData}>
              <XAxis
                    dataKey={barKeys[0]} />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend 
                height={36} 
                iconType="circle" 
                onClick={(props) => handleLegendClick(props.dataKey)} // NOSONAR - This prop is coming from recharts and should not be defined in propTypes
              /> 
    
              <Line type="monotone" dataKey={barKeys[0].dataKey} stroke="#8884d8" fill="#8884d8" />
              <Line
                  dot={false}
                  strokeWidth={3}
                  strokeLinecap="round"
                  dataKey="trend"
                  stroke={COLOURS.brand}
                  legendType="circle"
                  name="Trend Line"
                />
              
            </LineChart>
            )}
          </ResponsiveContainer>

        </div>
      )}
    </Card>
  );
};

TrendChart.propTypes = {
  projectId: PropTypes.number.isRequired,
  testTypeId: PropTypes.number.isRequired,
  metricType: PropTypes.string,
  barKeys: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartType: PropTypes.string
};

TrendIcon.propTypes = {
  overallTrend: PropTypes.number.isRequired,
  positiveDirection: PropTypes.string.isRequired,
  testTypeId: PropTypes.number.isRequired
};

export default TrendChart;