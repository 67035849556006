import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Card, Select, SelectItem } from "@tremor/react";
import { subDays, parseISO, isAfter } from 'date-fns';
import { Typography } from "@material-tailwind/react";

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

import chartsConfig from "../../../datahooks/metadata/PerformanceTrendCharts";

import useProjectTrends from "../../../datahooks/useProjectTrends";
import LoadingIcon from "../../navigation/LoadingIcon";
import AlertManager from "../../general/AlertManager";
import { ErrorCard, CARD_TYPES } from "../ErrorCard";

const PerformanceTrendChart = ({ projectId }) => {

  const [daysFilter, setDaysFilter] = useState(30); // Default filter to 30 days
  const [chartSelection, setChartSelection] = useState("all"); // Default to all metrics
  const [selectedScenario, setSelectedScenario] = useState("totals"); // Default to first scenario
  const [scenarioList, setScenarioList] = useState([]); // List of scenarios from totals
  const [filteredData, setFilteredData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // State to manage error messages
  const [activeSeries, setActiveSeries] = useState(() =>   // Prefill active series with all fields
    chartsConfig.reduce((acc, chart) => {
      return acc.concat(chart.fields);
    }, [])
  );

  const { projectTrend, isLoading, isError } = useProjectTrends(4, projectId, selectedScenario);

  // Load scenarios when "totals" data is available
  useEffect(() => {
    if (projectTrend?.totals && projectTrend?.scenarios) {
      const scenarioNames = Object.keys(projectTrend.scenarios);
      setScenarioList(scenarioNames);
    }
  }, [projectTrend?.totals]); // Only reload scenarios when totals change

  // Filter data based on selected scenario and days filter
  useEffect(() => {
    let dataToFilter = selectedScenario === 'totals' ? projectTrend?.totals : projectTrend;
    if (dataToFilter?.length > 0) {
      const currentDate = new Date();
      const filtered = dataToFilter.filter((item) => {
        const itemDate = parseISO(item.release_date);
        return isAfter(itemDate, subDays(currentDate, daysFilter));
      });
      setFilteredData(filtered);
    }
  }, [projectTrend, selectedScenario, daysFilter]);


  // Handle error state
  useEffect(() => {
    if (isError) {
      setErrorMessage("Error fetching data.");
    }
  }, [isError]);

  if (isError) {
    return <AlertManager className="m-2" errorMessage={errorMessage} setErrorMessage={setErrorMessage} />;
  }

  if (!isLoading && filteredData.length === 0 && daysFilter === 30) {
    return null;
  }

  const getMaxAcrossFields = (data, fields) => {
    let maxVal = 0;
    fields.forEach((field) => {
        if (activeSeries.includes(field)) {  // Only calculate for active series
        const maxForField = Math.max(...data.map(d => d.trend_data[field]));
        if (maxForField > maxVal) {
            maxVal = maxForField;
        }
        }
    });
    return maxVal;
  };

  const handleLegendClick = (dataKey) => {
    dataKey = dataKey.replace('trend_data.', ''); // Remove trend_data prefix
    setActiveSeries((prev) => {         // Toggle the active series
      if (prev.includes(dataKey)) {
        return prev.filter((field) => field !== dataKey);
      } else {
        return [...prev, dataKey];
      }
    });
  };

  return (
    <>
      <Card className="mb-5">
        <div className="flex justify-between flex-col lg:flex-row lg:items-center">
          <Typography variant='h2'>Recent Results</Typography>
          <div className="flex items-center flex-col space-y-2 lg:flex-row lg:items-center lg:space-x-2 lg:space-y-0">
          <Select value={chartSelection} onValueChange={setChartSelection} placeholder="Select Metric">

              <SelectItem value="all">All Metrics</SelectItem>
              {chartsConfig.map((chart) => (
                <SelectItem key={chart.id} value={chart.id}>{chart.name}</SelectItem>
              ))}
            </Select>

            <Select value={String(daysFilter)} onValueChange={(value) => setDaysFilter(Number(value))} placeholder="Select Days">
              <SelectItem value="7">Last 7 Days</SelectItem>
              <SelectItem value="15">Last 15 Days</SelectItem>
              <SelectItem value="30">Last 30 Days</SelectItem>
            </Select>

            <Select value={selectedScenario} onValueChange={setSelectedScenario} placeholder="Select Scenario">
              <SelectItem value="totals">All scenarios</SelectItem>
              {scenarioList.map((scenario) => (
                <SelectItem key={scenario} value={scenario}>{scenario.replace('_', ' ')}</SelectItem>
              ))}
            </Select>

          </div>
        </div>
      </Card>

      {isLoading && (
        <div className="w-full h-72 flex justify-center items-center">
          <LoadingIcon showText />
        </div>
      )}

    {!isLoading && filteredData.length === 0 ? (
      <Card className="w-full mb-5 h-72 flex flex-col justify-center items-center">
        <ErrorCard cardType={CARD_TYPES.EMPTY} />
        <Typography variant='h6'>No releases available for the selected period.</Typography>
      </Card>
    ) : (
      <div className={`w-full grid gap-5 mb-5 ${chartSelection === 'all' && 'lg:grid-cols-2'}`}>
          {chartsConfig.map((chart, index) => (
            (chartSelection === 'all' || chartSelection === chart.id) && (
              <Card key={chart.id} className="mb-2">
                <Typography variant='h3' className="mb-5">{chart.name}</Typography>
                <div className="w-full h-96">
                  <ResponsiveContainer>
                    <LineChart data={filteredData}>
                      <CartesianGrid stroke="#e6e6e6" strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="release_name" angle={90} dy={35} dx={5} height={85} />
                      <YAxis
                        domain={[0, getMaxAcrossFields(filteredData, chart.fields)]}
                        tickFormatter={(value) => chart.formatter(value)}
                        dx={2}
                      />
                      <Legend 
                        onClick={(props) => handleLegendClick(props.dataKey)} // NOSONAR - This prop is coming from recharts and should not be defined in propTypes
                        verticalAlign="top" 
                        height={45}/>
                      <Tooltip 
                        labelFormatter={(release_name) => release_name} 
                        formatter={(value) => {
                            return chart?.formatter(value);
                        }} 
                        />

                      {chart.fields.map((field) => (
                        <Line
                          key={field}
                          hide={!activeSeries.includes(field)}
                          type="monotone"
                          dataKey={`trend_data.${field}`}
                          stroke={chart.fieldColours[field]}
                          strokeWidth={2}
                          name={chart.readableNames[field]} // Use readable name in legend
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            )
          ))}
        </div>
        )} 
    </>
  );
};

PerformanceTrendChart.propTypes = {
  projectId: PropTypes.number.isRequired
};

export default PerformanceTrendChart;