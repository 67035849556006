import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionHeader, AccordionBody, Typography } from '@material-tailwind/react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import IssueStatus from '../../general/IssueStatus';
import { DonutChart, Card } from '@tremor/react';
import SeverityChip from '../shared/SeverityChip';
import { featureDetails } from '../../../datahooks/metadata/DataUtils';

const AccessibilityTests = ({ title, tests }) => {
    const [open, setOpen] = useState(0);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);
    const valueFormatter = (number) => number.toString();

    const createErrorPercentChartData = (errorPct) => {
        return [
            { name: 'Error', count: errorPct },
            { name: 'Success', count: 100 - errorPct }
        ];
    };

    return (
        <Card className='mt-6'>
            {title && <Typography variant='h4'>{title}</Typography>}
            {tests && tests.length > 0 ? (
                tests.map((test, index) => {
                    const errorPct = ((test?.axe?.totalViolationInstances / (test?.axe?.totalPassInstances + test?.axe?.totalViolationInstances)) * 100).toFixed(0);
                    const donutChartData = createErrorPercentChartData(errorPct);
                    const hasViolations = test?.axe?.violations?.count > 0;
                    const showAccordionBody = hasViolations || test.id; // Show the body if there are violations or if the test has an ID

                    return (
                        <Accordion key={test.id} open={open === index + 1}>
                            <AccordionHeader className="text-base font-normal" onClick={() => handleOpen(index + 1)}>
                                <div className="grid items-center w-full divide-x divide-gray-400" style={{ gridTemplateColumns: 'auto 1fr auto auto', boxSizing: 'border-box' }}>
                                    <div className="flex justify-end pl-3 pr-3" title={`Result: ${test.status}`}>
                                        <IssueStatus status={test.status} size={35} />
                                    </div>
                                    
                                    <Typography variant='lead' className="pl-5 pr-3 flex-grow" title={`Test: ${test.name}`}>
                                        {test.name}
                                    </Typography>

                                    <DonutChart
                                            style={{ height: '50px', width: '50px' }}
                                            className="self-center mr-3"
                                            data={donutChartData}
                                            category="count"
                                            index="name"
                                            colors={["pink", "green"]}
                                            label={`${100 - errorPct}%`}
                                            valueFormatter={valueFormatter}
                                            showAnimation
                                        />
                                    
                                    <div className="border-l border-gray-300">
                                        {open === index + 1 ? <MdExpandLess size={35} className='ml-3' /> : <MdExpandMore size={35} className='ml-3' />}
                                    </div>
                                </div>
                            </AccordionHeader>

                            {showAccordionBody && (
                                <AccordionBody className='p-3'>
                                    {hasViolations && (
                                        <div className="flex justify-end mr-2">
                                            <Typography variant="h4">Total Issues Found: {test.axe.totalViolationInstances}</Typography>
                                        </div>
                                    )}
                                    {hasViolations && test.axe.violations.details.map((violation, index) => (
                                        <div key={violation.helpUrl} className="grid w-full mb-3 border-b-2" style={{ gridTemplateColumns: '1fr auto auto', boxSizing: 'border-box' }}>
                                            <Typography variant="paragraph" className="p-2 ml-3 mr-3 font-medium" title="Violation">
                                                {violation.help}
                                            </Typography>
                                            <div className='ml-3 p-3'>
                                                <SeverityChip severity={violation.impact} isAccessibility />
                                            </div>
                                            <Typography variant="lead" className="p-2 ml-3 font-medium" title="Instances">
                                                {violation.nodes_count}
                                            </Typography>
                                        </div>
                                    ))}
                                    {test.id && featureDetails[5]?.detailLink && (
                                        <div className="flex justify-end mr-2">
                                            <Typography variant="paragraph">
                                                <a
                                                    href={featureDetails[5].detailLink.replace('{id}', test.id)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 font-semibold"
                                                >
                                                    For more details, click here.
                                                </a>
                                            </Typography>
                                        </div>
                                    )}
                                </AccordionBody>
                            )}
                        </Accordion>
                    );
                })
            ) : (
                <p>No tests to display</p>
            )}
        </Card>
    );
};

AccessibilityTests.propTypes = {
    title: PropTypes.string,
    tests: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            status: PropTypes.string,
            axe: PropTypes.shape({
                totalViolationInstances: PropTypes.number,
                totalPassInstances: PropTypes.number,
                violations: PropTypes.shape({
                    count: PropTypes.number,
                    details: PropTypes.arrayOf(
                        PropTypes.shape({
                            helpUrl: PropTypes.string,
                            help: PropTypes.string,
                            impact: PropTypes.string,
                            nodes_count: PropTypes.number
                        })
                    )
                })
            })
        })
    )
};

export default AccessibilityTests;
