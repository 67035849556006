import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionHeader, AccordionBody, Typography } from '@material-tailwind/react';
import { DonutChart, BarChart, Card } from '@tremor/react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

const PerformanceTransactions = ({ title, transactions }) => {
    const [open, setOpen] = useState(0);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);
    const valueFormatter = (number) => number.toString();

    const createErrorPercentChartData = (errorPct) => {
        return [
            { name: 'Error', count: errorPct },
            { name: 'Success', count: 100 - errorPct }
        ];
    };

    const createResponseTimeChartData = (transaction) => {
        return [
            {
                name: 'Response Times',
                '90th Percentile': transaction.pct1ResTime.toFixed(2),
                '95th Percentile': transaction.pct2ResTime.toFixed(2),
                '99th Percentile': transaction.pct3ResTime.toFixed(2),
                'Max Response Time': transaction.maxResTime.toFixed(2)
            }
        ];
    };

    const responseTimeChartTooltip = ({ payload, active }) => {
        if (!active || !payload) return null;
        return (
          <div className="w-56 rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
            <div className='border-b-2 border-b-gray-500 mb-2'>Response times</div>
            {payload.map((category) => {
                const key = category.name.replace(/\s+/g, '_'); // Replace spaces with underscores
                
                return (
                <div key={key} className="flex flex-1 space-x-2.5">
                    <div className={`w-1 flex flex-col bg-${category.color}-500 rounded-full`} />
                    <div className="space-y-1 flex text-right">
                    <p className="text-tremor-content justify-self-end flex text-right">{category.dataKey}: {category.value}ms</p>
                    </div>
                </div>);
            })}
          </div>
        );
      };

    return (
        <Card className='mt-6'>
            {title && <Typography variant="h3">{title}</Typography>}
            {transactions && transactions.length > 0 ? (
                transactions.map((transaction, index) => {
                    const donutChartData = createErrorPercentChartData(transaction.errorPct);
                    const barChartData = createResponseTimeChartData(transaction);

                    return (
                        <Accordion key={transaction.transaction} open={open === index + 1}>
                            <AccordionHeader className="text-base font-normal" onClick={() => handleOpen(index + 1)}>
                                <div className="grid items-center w-full" style={{ gridTemplateColumns: '1fr 1fr auto auto' }}>
                                    
                                    <Typography variant='lead' className="pl-3 pr-3 flex-grow break-words" style={{ whiteSpace: 'normal' }}>
                                        {transaction.transaction}
                                    </Typography >

                                    <Typography variant='lead' className="pl-5 pr-5 mr-3 border-l border-r border-gray-300 flex-grow break-words justify-self-end" style={{ whiteSpace: 'normal' }}>
                                        {transaction.sampleCount} samples
                                    </Typography >

                                    <DonutChart
                                        style={{ height: '50px', width: '50px' }}
                                        className="self-center mr-3 "
                                        data={donutChartData}
                                        category="count"
                                        index="name"
                                        colors={["pink", "green"]}
                                        label={`${100-transaction.errorPct.toFixed(0)}%`}
                                        valueFormatter={valueFormatter}
                                        showAnimation
                                    
                                    />

                                    <div className=" border-l border-gray-300">
                                        {open === index + 1 ? <MdExpandLess size={35} className='ml-3'/> : <MdExpandMore size={35} className='ml-3'/>}
                                    </div>
                                </div>
                            </AccordionHeader>
                            <AccordionBody className='p-3 bg-transparent'>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                   
                                    <div className="flex justify-between py-1">
                                        <Typography variant='paragraph'>Mean Response Time:</Typography>
                                        <Typography variant='paragraph'>{`${transaction.meanResTime.toFixed(2)}ms`}</Typography>
                                    </div>
                                    
                                    <div className="flex justify-between py-1">
                                        <Typography variant='paragraph'>Min/Max Response Time:</Typography>
                                        <Typography variant='paragraph'>{`${transaction.minResTime.toFixed(2)}ms / ${transaction.maxResTime.toFixed(2)}ms`}</Typography>
                                    </div>
                                   
                                    <div className="flex justify-between py-1">
                                        <Typography variant='paragraph'>Throughput:</Typography>
                                        <Typography variant='paragraph'>{`${transaction.throughput.toFixed(3)} requests per second`}</Typography>
                                    </div>
                                    <div className="flex justify-between py-1">
                                        <Typography variant='paragraph'>Received KBytes/sec:</Typography>
                                        <Typography variant='paragraph'>{`${transaction.receivedKBytesPerSec.toFixed(3)}`}</Typography>
                                    </div>
                                    <div className="flex justify-between py-1">
                                        <Typography variant='paragraph'>Sent KBytes/sec:</Typography>
                                        <Typography variant='paragraph'>{`${transaction.sentKBytesPerSec.toFixed(3)}`}</Typography>
                                    </div>
                                    <div className="flex justify-between py-1">
                                        <Typography variant='paragraph'>Error Count:</Typography>
                                        <Typography variant='paragraph'>{transaction.errorCount}</Typography>
                                    </div>
                                </div>

                                    <div>
                                        
                                        <BarChart
                                            data={barChartData}
                                            index="name"
                                            categories={['90th Percentile', '95th Percentile', '99th Percentile', 'Max Response Time']}
                                            colors={["blue", "green", "orange", "red"]}
                                            valueFormatter={valueFormatter}
                                            yAxisWidth={48}
                                            customTooltip={responseTimeChartTooltip}
                                        />
                                    </div>
                                </div>
                            </AccordionBody>
                        </Accordion>
                    );
                })
            ) : (
                <p>No transactions to display</p>
            )}
        </Card>
    );
};

PerformanceTransactions.propTypes = {
    title: PropTypes.string,
    transactions: PropTypes.arrayOf(
        PropTypes.shape({
            transaction: PropTypes.string,
            errorPct: PropTypes.number,
            sampleCount: PropTypes.number,
            meanResTime: PropTypes.number,
            minResTime: PropTypes.number,
            maxResTime: PropTypes.number,
            throughput: PropTypes.number,
            receivedKBytesPerSec: PropTypes.number,
            sentKBytesPerSec: PropTypes.number,
            errorCount: PropTypes.number,
            pct1ResTime: PropTypes.number,
            pct2ResTime: PropTypes.number,
            pct3ResTime: PropTypes.number,
        })
    ),
};

export default PerformanceTransactions;
