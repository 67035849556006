import { Button, Navbar } from "@material-tailwind/react";
import PropTypes from 'prop-types';
import { HiMenu } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import SideBar from '../components/navigation/SideBar.jsx';
import { useAuth } from '../components/navigation/Authentication.jsx';
import SpinningTitle from "../components/general/SpinningTitle.jsx";
import UserMenu from "../components/navigation/UserMenu.jsx";
import Footer from "../components/navigation/Footer.jsx";
import LoadingIcon from "../components/navigation/LoadingIcon.jsx";
import { useNavigate, Link } from 'react-router-dom';
import { PROJECT_SCOPE } from '../config.js';

function AuthenticatedLayout({ children }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { user, isInitialised, signOut, acquireTokenSilent } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isInitialised) {
            if (!user) {
                navigate('/'); // Redirect to login if not authenticated
            } else {
                // Validate the token by calling acquireTokenSilent
                acquireTokenSilent([PROJECT_SCOPE])
                    .then(() => {
                        // Token is valid, allow navigation
                        return null;
                    })
                    .catch((error) => {
                        console.error('Token is invalid or expired:', error);
                        signOut();  // Sign out the user if token is invalid
                    });
            }
        }
    }, [user, isInitialised, navigate, acquireTokenSilent, signOut]);

    if (!isInitialised || !user) {
        // Show loading icon while checking user authentication
        return <LoadingIcon size={150} showText />;
    }
    //linear-gradient(315deg, var(#e9f1ff), white)
    // style={{ background: 'linear-gradient(315deg, #e9f1ff, white)' }}>}
    return (
        <div className='bg-white-gradient'>
            <Navbar shadow={false} blurred={false} data-testid="authenticated-navbar"
                className="flex items-center border-b justify-between top-0 relative w-full max-w-full bg-transparent px-2 py-0 my-0 lg:px-4 lg:py-0 "
                style={{ position: 'relative', zIndex: '21' }}
            >
                <div className="flex items-center flex-shrink-0 m-4 sm:m-6">
                    <Link to={"/home"}>
                        <img src="/assets/logo/tiqtoq.svg" className="sm:h-12 h-8" alt="tiQtoQ Logo" data-testid='tiqtoq-logo' />
                    </Link>
                </div>
                
                <SpinningTitle className="hidden md:block flex-grow" />

                <div className="flex items-center flex-shrink-0">
                    <UserMenu doLogout={signOut}/>
                    <Button className='md:hidden ml-3' variant="outlined" onClick={() => setDrawerOpen(!drawerOpen)}>
                        <HiMenu className="h-5 w-5 p-0 m-0" />
                    </Button>
                </div>
            </Navbar>

            <div className="flex flex-col min-h-screen">
                <div className="flex flex-grow">
                    <SideBar handleClick={setDrawerOpen} drawerOpen={drawerOpen} />
                    <div className="flex-grow overflow-auto px-4 sm:px-6 lg:px-8">
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

AuthenticatedLayout.propTypes = {
    children: PropTypes.node,
};

export default AuthenticatedLayout;