// Utility to calculate the Exponential Moving Average (EMA)
export const calculateEMA = (data, key, period = 10) => {
    const ema = [];
    let previousEma;
    const alpha = 2 / (period + 1);  // Smoothing factor based on the period

    data.forEach((item, index) => {
      if (index === 0) {
        previousEma = item[key];  // The first EMA is just the first data point
      } else {
        // EMA calculation using the standard formula
        previousEma = (item[key] - previousEma) * alpha + previousEma;
      }
       // Round the trend value to two decimal places
        ema.push({ ...item, trend: Math.round(previousEma * 100) / 100 });
    });

    return ema;
};

// Function to format the tooltip based on metric type
export const formatTooltipValue = (value, metricType, selectedMetric, name) => {
    
  if (selectedMetric === 'technical_debt') {
    return `${Number(value).toLocaleString()} hours`; // Format as hours for technical debt
  }

  if ((selectedMetric === 'response_time') || (selectedMetric === '95th_pc_response_time')) {
    return `${value.toFixed(2)}ms`; // Format as milliseconds for response time
  }

  if (selectedMetric === 'throughput') {
    return `${Number(value.toFixed(2)).toLocaleString()}tps`;
  }

  if ((metricType === 'percentage') || (selectedMetric === 'error_percentage')) {
    return `${value.toFixed(2)}%`; // Format as percentage
  }

  if (name === 'Trend Line') {
    return `${Number(value.toFixed(2)).toLocaleString()}`; // Format as two decimal places for trend line
  }
  
  return Number(value).toLocaleString(); // Default format for other metrics
};

export function getGradient(metricType, barDataKey) {
  // For percentage metrics, base the gradient on the barDataKey (pass or fail)
  if (metricType === "percentage") {
    return barDataKey === 'passed' ? 'url(#passGradient)' : 'url(#failGradient)';
  }
  
  //For non percentage metrics (SCA metrics, which are negatives), base the gradient on the fail colour
  return 'url(#failGradient)';
}

export const SCA_METRICS = [
  { metric: 'bugs', goodDir: 'down', label: 'Bugs' },
  { metric: 'code_smells', goodDir: 'down', label: 'Code Smells' },
  { metric: 'vulnerabilities', goodDir: 'down', label: 'Vulnerabilities' },
  { metric: 'technical_debt', goodDir: 'down', label: 'Technical Debt' },
  { metric: 'duplicated_blocks', goodDir: 'down', label: 'Duplicated Blocks' },
  { metric: 'cognitive_complexity', goodDir: 'down', label: 'Cognitive Complexity' },
];

export const PERFORMANCE_METRICS = [
  { metric: 'response_time', goodDir: 'down', label: 'Mean Response Time' },
  { metric: 'error_percentage', goodDir: 'down', label: 'Error Percentage' },
  { metric: 'throughput', goodDir: 'up', label: 'Throughput' },
  { metric: '95th_pc_response_time', goodDir: 'down', label: '95th Percentile Response Time' },
];
