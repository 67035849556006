import React from "react";
import { Typography, Chip } from "@material-tailwind/react";
import PropTypes from 'prop-types';
import PopFunctionalUI from "../shared/popupExplanations/PopFunctionalUI";
import FunctionalFirstRow from "./FunctionalFirstRow";
import FunctionalThirdRow from "./FunctionalThirdRow";
import { MdOutlineTimer } from "react-icons/md";
import { getTimeDifference } from "../../../datahooks/metadata/DataUtils";
import TrendChart from "../shared/TrendChart";

export default function FunctionalUITestType({ resultData, projectId }) {
  
  const barKeys = [
    { dataKey: "passed", name: "Passed Tests" },
    { dataKey: "failed", name: "Failed Tests" },
  ];

  return (
    <>
      <div className='flex flex-row items-center mb-5 pb-2 border-b border-gray-400 gap-2'>
          <div className="w-full mb-1 flex flex-row items-center">
              <Typography variant="h2" className="text-blue mr-1">Functional UI</Typography>
              <PopFunctionalUI page size={25} />
          </div>
          <Chip color="blue" value={getTimeDifference(resultData?.creation, resultData.last_update)} variant="outlined" icon={<MdOutlineTimer size={20}/>} />
          <Chip color="blue" value={resultData?.env} />
      </div>

      <TrendChart
        projectId={projectId}
        metricType="percentage"
        yAxisDomain={[0, 100]}
        testTypeId={3}
        barKeys= {barKeys}
      />

      <FunctionalFirstRow projectData={resultData.result_json} />

      <FunctionalThirdRow projectData={resultData.result_json} />
    </>
  );
}

FunctionalUITestType.propTypes = {
  resultData: PropTypes.object,
  projectId: PropTypes.number,
};
