import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Card } from "@tremor/react";
import { Typography } from "@material-tailwind/react";

const PerformanceLineChart = ({ theData, theIndex, title }) => {
  // Define the categories (transaction names)
  // This assumes that each entry in the transformed data has the same set of transaction names.
  // You can adjust this logic as needed based on the actual structure of responseTimeDistrib.
  const categories = theData?.length > 0 
    ? Object.keys(theData[0]).filter(key => key !== theIndex) 
    : [];

    const customTooltip = ({ payload, active }) => {
      if (!active || !payload) return null;
      
      return (
        <div className=" rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
          {payload.map((category) => {
            const key = category.name.replace(/\s+/g, '_'); // Replace spaces with underscores
            return (
              <div key={key} className="flex flex-1 space-x-2.5 mb-2">
                <div className={`w-1 flex flex-col bg-${category.color}-500 rounded`} />
                <div className="space-y-1">
                  <p className="text-tremor-content">{category.dataKey} at {category.payload.minute} was {category.value.toFixed(2)} transactions / sec </p>
                </div>
              </div>
            );
          })}
        </div>
      );
    };

  return (
    <Card className='mt-3 mb-3'>
      <Typography variant="h3">{title}</Typography>
      <LineChart
        className="mt-6"
        data={theData}
        index={theIndex}
        categories={categories}
        customTooltip={customTooltip}
        colors={['blue', 'yellow', 'pink', 'orange', 'green', 'cyan',  'gray']}
      />
    </Card>
  );
};

PerformanceLineChart.propTypes = {
  theData: PropTypes.array,
  theIndex: PropTypes.string,
  title: PropTypes.string
};

export default PerformanceLineChart;