import React from 'react';
import PropTypes from 'prop-types';
import { HiHome, HiUsers } from 'react-icons/hi';
import { VscOrganization } from "react-icons/vsc";
import { BsClockHistory } from "react-icons/bs";
import { LuTestTubes } from "react-icons/lu";
import { PiFoldersFill } from "react-icons/pi";

import { List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import useUser from '../../datahooks/useUser';

/**
 * This component is used to generate the links for the menu.
 * This allows us to include the same links in the drawer and sidebar component,
 * drawer for mobile, sidebar for desktop.
 *
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered list of menu links.
 */
const MenuLinksAdmin = () => {
  const { user:userDetails } = useUser();

  return (
    <List data-testid="menuLinksAdmin">
        <Link to={"/admin"}>
            <ListItem>
                <ListItemPrefix><HiHome className="h-5 w-5" /></ListItemPrefix>
                <Typography variant='paragraph'>Admin Home</Typography>
            </ListItem>
        </Link>
        
        <hr className="my-2 border-blue-gray-50" />
        <Link to={"/admin_organisation"}>
            <ListItem>
                <ListItemPrefix><VscOrganization className="h-5 w-5" /></ListItemPrefix>
                <Typography variant='paragraph'>Organisation</Typography>
            </ListItem>
        </Link>
        <Link to={"/admin_projects"}>
            <ListItem>
                <ListItemPrefix><PiFoldersFill className="h-5 w-5" /></ListItemPrefix>
                <Typography variant='paragraph'>Projects</Typography>
            </ListItem>
        </Link>
        <Link to={"/admin_users"}>
            <ListItem>
                <ListItemPrefix><HiUsers className="h-5 w-5" /></ListItemPrefix>
                <Typography variant='paragraph'>Users</Typography>
            </ListItem>
        </Link>
        {userDetails?.user_type === 'super_admin' ? (
            <>
                <hr className="my-2 border-blue-gray-50" />
                <Link to={"/admin_testtypes"}>
                    <ListItem>
                        <ListItemPrefix><LuTestTubes className="h-5 w-5" /></ListItemPrefix>
                        <Typography variant='paragraph'>Test Types</Typography>
                    </ListItem>
                </Link>
                <Link to={"/admin_auditlog"}>
                    <ListItem>
                        <ListItemPrefix><BsClockHistory className="h-5 w-5" /></ListItemPrefix>
                        <Typography variant='paragraph'>Audit Log</Typography>
                    </ListItem>
                </Link>
            </>
        ) : null }
    </List>
  );
}

export default MenuLinksAdmin;
