import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { TextInput, Card, Select, SelectItem } from "@tremor/react";
import { Typography, Button, Navbar } from '@material-tailwind/react';
import { FaSave, FaBan } from 'react-icons/fa';

import AlertManager from '../../general/AlertManager.jsx';

import * as yup from 'yup';
import _ from 'lodash';

import useProjectTestType from '../../../datahooks/admin/project/useProjectTestType.js';
import useProjectTestTypeManager from '../../../datahooks/admin/project/useProjectTestTypesManager.js';
import useTestTypes from '../../../datahooks/admin/project/useTestTypes.js';
import { testTypesConfig } from '../../../datahooks/admin/project/testTypesConfig.js';

import LoadingIcon from '../../navigation/LoadingIcon.jsx';
import { ErrorCard, CARD_TYPES } from '../../cards/ErrorCard.jsx';
import EditableTextInput from '../general/EditableTextInput.jsx';

// Define validation schema for the form
const testTypeSchema = yup.object().shape({
  test_type_id: yup.number().integer(),
  api_url: yup
    .string()
    .url('Please enter a valid URL.')
    .required('API URL is required.')
    .max(255, 'API URL should not exceed 255 characters.'),
  api_key: yup
    .string()
    .optional()
    .max(255, 'API key should not exceed 255 characters.'),
  api_username: yup
    .string()
    .optional()
    .max(255, 'API username should not exceed 255 characters.'),
  api_password: yup
    .string()
    .optional()
    .max(255, 'API password should not exceed 255 characters.'),
});

const ProjectTestTypesEditForm = ({ acdId = -1, addingTestType, onCancel, onUpdatedTestType, projectId }) => {

    const projectTestTypeUpdated = (aResponse) => {
        if (!addingTestType) {
          setSuccessMessage("Project test type updated successfully."); 
          refetchProjectTestTypes();
        }
        
        if (onUpdatedTestType) {
          onUpdatedTestType();
        }
    }

    const projectTestTypeSaveFailed = (error) => {
        setErrorMessage(`Failed to save project test type: ${error?.errorResponse?.message || 'Unknown error'}`);
    }

    const { performAction } = useProjectTestTypeManager(projectTestTypeUpdated, projectTestTypeSaveFailed);

    const { testTypesData, loadingTestTypes, testTypesError } = useTestTypes(addingTestType);

    // Always call the hook and handle the condition inside the hook
    const { projectTestTypeData, loadingProjectTestType, refetchProjectTestTypes, projectTestTypeError } = useProjectTestType(acdId, addingTestType);
    
    const [initialValues, setInitialValues] = useState({
      test_type_id: -1,
      api_url: '',
      api_key: '',
      api_username: '',
      api_password: ''
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [currentConfig, setCurrentConfig] = useState(testTypesConfig["-1"]);

  // Set initial values based on editing or adding a new project
  useEffect(() => {
    const getDefaultInitialValues = () => ({
      test_type_id: -1,
      api_url: '',
      api_key: '',
      api_username: '',
      api_password: ''
    });

    if (addingTestType) {
      setCurrentConfig(testTypesConfig["-1"]); // Default state when adding a new test type
      const newInitialValues = getDefaultInitialValues();

      // Only update if values have changed to prevent rerenders
      if (!_.isEqual(initialValues, newInitialValues)) {
        setInitialValues(newInitialValues);
      }
    } else if (projectTestTypeData) {
      const newInitialValues = {
        test_type_id: projectTestTypeData.test_type_id || -1,
        api_url: projectTestTypeData.api_url || '',
        api_key: projectTestTypeData.api_key || '',
        api_username: projectTestTypeData.api_username || '',
        api_password: projectTestTypeData.api_password || '',
      };
      
      // Only update if values have changed to prevent rerenders
      if (!_.isEqual(initialValues, newInitialValues)) {
        setCurrentConfig(testTypesConfig[projectTestTypeData?.testTypeId]);
        setInitialValues(newInitialValues);
      }
    }
  }, [addingTestType, projectTestTypeData]);

  const handleSubmit = async (values) => {
    // Validate the form
    try {
      await testTypeSchema.validate(values, { abortEarly: false });

      await doSave();

    } catch (error) {
      // If the form is invalid, capture and set the validation errors
      const validationErrors = error.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      formik.setErrors(validationErrors);
    }
  };

  const doSave = async () => {
    if (addingTestType) {
      if (!formik.values.test_type_id || !formik.values.api_url) {
        console.error("A test type and URL are required.");
        return; // Return early if required fields are missing
      }

      await doAddTestType();

    } else {
        await doUpdateTestType();
    }
  }

  const doAddTestType = async (imagePath = null) => {
    const payload = {
      new_project_id: projectId,
      new_test_type_id: formik.values.test_type_id,
      new_api_url: formik.values.api_url
    };

    if (formik.values.api_key) { payload.new_api_key = formik.values.api_key; }

    if (formik.values.api_username) { payload.new_api_username = formik.values.api_username; }

    if (formik.values.api_password) { payload.new_api_password = formik.values.api_password; }

    return performAction(payload, 'PUT'); 
  }

  const doUpdateTestType = () => {

    const payload = { api_id: acdId };
    
    // Update only if values have changed
    if (!_.isEqual(initialValues.api_url, formik.values.api_url)) { payload.new_api_url = formik.values.api_url; }

    if (!_.isEqual(initialValues.api_username, formik.values.api_username)) { payload.new_api_username = formik.values.api_username; }

    if (!_.isEqual(initialValues.api_password, formik.values.api_password)) { payload.new_api_password = formik.values.api_password; }

    if (!_.isEqual(initialValues.api_key, formik.values.api_key)) { payload.new_api_key = formik.values.api_key; }

    // Check if there are changes to submit
    if (Object.keys(payload).length > 1) {
        return performAction(payload, 'POST'); // Use POST for updating projects
    }
  }

  const formik = useFormik({
      initialValues,
      validationSchema: testTypeSchema,
      enableReinitialize: true, 
      onSubmit: handleSubmit, 
  });

  const handleTestTypeChange = (value) => {
    formik.setFieldValue('test_type_id', value);
    setCurrentConfig(testTypesConfig[value] || testTypesConfig["-1"]);
  }

  if (loadingProjectTestType || loadingTestTypes) {
    return <LoadingIcon showText />
  }

  if (projectTestTypeError || testTypesError) {
    return <div><ErrorCard cardType={CARD_TYPES.EMPTY} /> {projectTestTypeError?.message || testTypesError?.message}</div>; // Handle error state
  }

  return (
    <Card className="flex flex-col w-full h-full text-blue-500 items-center">
      { addingTestType ? (
        <Typography variant="h5" className="text-blue-500 mt-2">Add New Test Type</Typography>
      ) : (
        <Typography variant="h5" className="text-blue-500 mt-4">Test Type: {projectTestTypeData?.testTypeTitle}</Typography>
      )}

      <form onSubmit={formik.handleSubmit} className="flex flex-col w-full max-w-4xl">
        {addingTestType &&
          <>
            <Typography variant="h5" className="text-blue-500 mt-4">Test Type</Typography>
            <Select
              className='mt-Select-button'
              label="Test Type"
              value={formik.values.test_type_id}
              onChange={handleTestTypeChange}
              onBlur={() => formik.setFieldTouched('test_type_id', true)}
              error={formik.touched.test_type_id && Boolean(formik.errors.test_type_id)}
            >
              {testTypesData?.map((type) => (
                <SelectItem
                  className='mt-Select-option'
                  key={type.id} value={type.id}>{type.title}</SelectItem>
              ))}
            </Select>
            {formik.touched.test_type_id && formik.errors.test_type_id && (
              <div className="text-red-500 text-sm">{formik.errors.test_type_id}</div>
            )}

          </>
        }

        <Typography variant="h5" className="text-blue-500 mt-4">API URL</Typography>
        <TextInput
          label="API URL"
          name="api_url"
          placeholder="Enter the API URL"
          value={formik.values.api_url}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.api_url && formik.errors.api_url}
          errorMessage={formik.errors.api_url}
        />

      {currentConfig?.fieldsToShow.includes('api_key') && (
        <>
          <Typography variant="h5" className="text-blue-500 mt-4">{currentConfig?.labels.api_key || "API Key"}</Typography>
          <EditableTextInput
            label={currentConfig?.labels.api_key || "API Key"}
            placeholder={currentConfig?.helpText.api_key || "Enter an API key"}
            name="api_key"
            type="text"
            value={formik.values.api_key}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.api_key && formik.errors.api_key}
            errorMessage={formik.errors.api_key}
            setFieldValue={formik.setFieldValue}
            initialValue={formik.initialValues.api_key}
          />
        </>
      )}

      {currentConfig?.fieldsToShow.includes('api_username') && (
        <>
          <Typography variant="h5" className="text-blue-500 mt-4">{currentConfig?.labels.api_username || "API Username"}</Typography>
          <TextInput
            label={currentConfig?.labels.api_username || "API Username"}
            placeholder={currentConfig?.helpText.api_username || "Enter an API Username"}
            name="api_username"
            value={formik.values.api_username}
            error={formik.touched.api_username && formik.errors.api_username}
            errorMessage={formik.errors.api_username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </>
      )}

      {currentConfig?.fieldsToShow.includes('api_password') && (
      <>
        <Typography variant="h5" className="text-blue-500 mt-4">{currentConfig?.labels.api_password || "API Password"}</Typography>
        <EditableTextInput
            label={currentConfig?.labels.api_password || "API Password"}
            placeholder={currentConfig?.helpText.api_password || "Enter an API Password"}
            name="api_password"
            value={formik.values.api_password}
            type="text"
            error={formik.touched.api_password && formik.errors.api_password}
            errorMessage={formik.errors.api_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue}
            initialValue={formik.initialValues.api_password}
          />
      </>
      )}

        <AlertManager 
            successMessage={successMessage} 
            setSuccessMessage={setSuccessMessage} 
            errorMessage={errorMessage} 
            setErrorMessage={setErrorMessage} 
        />

        <Navbar className="m-0 p-0 mt-5 flex items-center justify-end w-full p-4 gap-4 bg-gray-100">
            
            <div className="flex ml-auto gap-4">
                <Button 
                    color="blue" 
                    variant="outlined" 
                    onClick={onCancel}
                    className="flex text-blue-500 items-center gap-3"
                >
                    <FaBan /> Cancel
                </Button>
                <Button 
                    color="blue" 
                    type="submit" 
                    disabled={!formik.dirty && !formik.isSubmitting}
                    className="flex items-center gap-3">
                    <FaSave /> Save
                </Button>
            </div>
        </Navbar>
      </form>

    </Card>
  );
};

ProjectTestTypesEditForm.propTypes = {
  acdId: PropTypes.number,
  addingTestType: PropTypes.bool,
  onCancel: PropTypes.func,
  onUpdatedTestType: PropTypes.func,
  projectId: PropTypes.number,
};

export default ProjectTestTypesEditForm;