import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionHeader,
  AccordionBody
} from '@material-tailwind/react';
import { Card } from "@tremor/react";
import LoadingIcon from '../../navigation/LoadingIcon';
import { featureDetails } from '../../../datahooks/metadata/DataUtils';
import FunctionalUIItemHeader from './FunctionalUIItemHeader.jsx';
import FunctionalUIItemDetails from './FunctionalUIItemDetails.jsx';
import Pagination from '../shared/Pagination.jsx';

export default function FunctionalThirdRow({ projectData }) {
  const [open, setOpen] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [itemsPerPage, setItemsPerPage] = useState(10); // For items per page

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  // Calculate total pages
  const totalPages = Math.ceil(projectData?.items?.length / itemsPerPage);

  // Get current items for the page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = projectData?.items?.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle change in items per page
  const handleItemsPerPageChange = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
    setCurrentPage(1); // Reset to page 1 when changing items per page
  };

  return (
    <Card>
      {projectData?.items ? (
        <>
          {currentItems.map((test, index) => {
            const detailLink = featureDetails[3].detailLink.replace('{id}', test.id);

            return (
              <Accordion key={test.id} open={open === index + 1} data-testid="accordion">
                <AccordionHeader className="text-base font-normal" onClick={() => handleOpen(index + 1)}>
                  <FunctionalUIItemHeader test={test} />
                </AccordionHeader>
                <AccordionBody className='p-3 flex flex-col select-none'>
                  <FunctionalUIItemDetails test={test} detailLink={detailLink} />
                </AccordionBody>
              </Accordion>
            );
          })}

          {/* Add Pagination component */}
          {projectData.items.length > 10 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              showItemsPerPageDropdown={true}
            />
          )}
        </>
      ) : (
        <LoadingIcon showText />
      )}
    </Card>
  );
};

FunctionalThirdRow.propTypes = {
  projectData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
        name: PropTypes.string,
        area: PropTypes.string,
        priority: PropTypes.string,
        creation_time: PropTypes.string,
        browser_normalized: PropTypes.string,
        os_normalized: PropTypes.string,
        duration: PropTypes.number
      })
    )
  })
};