import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionHeader, AccordionBody, Typography } from '@material-tailwind/react';
import { BarChart, Card, Title } from '@tremor/react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

const ErrorList = ({ title, topErrors, totalSamples }) => {
    const [open, setOpen] = useState(null);

    const handleOpen = (index) => {
        setOpen(open === index ? null : index);
    };

    const formatScenarioErrorData = (scenarios) => {
        const formattedData = { name: "Error Count" };
        Object.entries(scenarios).forEach(([scenario, { count }]) => {
            formattedData[scenario] = count;
        });
        return [formattedData];
    };

    return (
        <Card className='mt-6'>
            {title && <Typography variant="h3">{title}</Typography>}
            {topErrors && topErrors.length > 0 ? (
                topErrors.map(([errorKey, errorData], index) => (
                    <Accordion key={errorKey} open={open === index + 1}>
                        <AccordionHeader className="text-base font-normal" onClick={() => handleOpen(index + 1)}>
                            <div className="grid items-center w-full" style={{ gridTemplateColumns: '1fr auto auto' }}>
                                
                                <Typography variant='lead' className="pl-3 pr-3 flex-grow break-words" style={{ whiteSpace: 'normal' }}>
                                    {errorData.error_description}
                                </Typography>

                                <Typography variant='lead' className="pl-5 pr-5 mr-3 border-l border-r border-gray-300 flex-grow break-words justify-self-end" style={{ whiteSpace: 'normal' }}>
                                    {((errorData.totalErrorCount / totalSamples) * 100).toFixed(2)}%
                                </Typography>

                                <div className="border-l border-gray-300">
                                    {open === index + 1 ? <MdExpandLess size={35} className='ml-3' /> : <MdExpandMore size={35} className='ml-3' />}
                                </div>
                            </div>
                        </AccordionHeader>

                        <AccordionBody className='p-3 bg-transparent'>
                            
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            
                                <div>
                                    <div className="flex flex-col">
                                        <Typography variant='paragraph' className="py-1 mt-4"><strong>Total occurrences: </strong>{`${errorData.totalErrorCount.toLocaleString()} errors out of ${totalSamples.toLocaleString()} users`}</Typography>
                                        
                                        <Typography variant='paragraph' className="py-1 mt-4"><strong>Scenario Breakdown:</strong></Typography>
                                        {Object.entries(errorData.scenarios).map(([scenario, { count, samples }]) => (
                                            <Typography key={scenario} variant='paragraph'>{`${scenario}: ${count.toLocaleString()} errors out of ${samples.toLocaleString()} users`}</Typography>
                                        ))}
                                    </div>
                                </div>

                                <div>
                                    <BarChart
                                        className="mt-6"
                                        data={formatScenarioErrorData(errorData.scenarios)} 
                                        index="name"
                                        colors={['blue', 'yellow', 'pink', 'orange', 'green', 'cyan', 'gray']}
                                        valueFormatter={(value) => value.toString()}
                                        categories={Object.keys(errorData.scenarios)}
                                        yAxisWidth={48}
                                        showAnimation
                                    />
                                </div>
                            </div>
                        </AccordionBody>
                    </Accordion>
                ))
            ) : (
                <p>No issues to display</p>
            )}
        </Card>
    );
};

ErrorList.propTypes = {
    title: PropTypes.string,                  // Optional title for the error list
    topErrors: PropTypes.array.isRequired,    // Array of error data objects
    totalSamples: PropTypes.number.isRequired // Total samples count for percentage calculation
};

export default ErrorList;