import React from 'react';
import PropTypes from 'prop-types';

const FooterLink = ({ url, hoverBright=true, children, className = '' }) => {
  return (
    <a 
      href={url} 
      className={`${hoverBright ? 'opacity-75 hover:opacity-100' : ''} transition-opacity duration-300 font-light text-white text-lg flex items-center justify-center my-2 ${className}`}
      target="_blank" 
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

FooterLink.propTypes = {
  url: PropTypes.string.isRequired,
  hoverBright: PropTypes.bool,
  children: PropTypes.node,
};

export default FooterLink;