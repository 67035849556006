import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-tailwind/react';

const FunctionalUIItemDetails = ({ test, detailLink }) => {
  return (
    <>
      <div data-testid="fui-item-detail" className="flex flex-col md:flex-row justify-between">
        <Typography variant="paragraph" className="p-2 font-medium" title="Start time">
          {test.creation_time ? new Date(test.creation_time).toLocaleString() : ''}
        </Typography>
        <Typography variant="paragraph" className="p-2 font-medium" title="Browser">
          {test.browser_normalized ? test.browser_normalized : ''}
        </Typography>
        <Typography variant="paragraph" className="p-2 font-medium" title="Operating">
          {test.os_normalized ? test.os_normalized : ''}
        </Typography>
        <Typography variant="paragraph" className="p-2 font-medium" title="Duration">
          {test.duration !== null && test.duration !== undefined ? `${test.duration} sec` : ''}
        </Typography>
        {(test.retryCount && test.retryCount > 0) &&
          <Typography variant="paragraph" className="p-2 font-medium" title="Retries">
            {`Retries: ${test.retryCount}`}
          </Typography>
        }
      </div>
      {detailLink && (
        <div className="pt-2 text-right">
          <Typography variant="paragraph">
            <a href={detailLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 font-semibold">
              For more details, click here.
            </a>
          </Typography>
        </div>
      )}
    </>
  );
};

FunctionalUIItemDetails.propTypes = {
  test: PropTypes.shape({
    creation_time: PropTypes.string,
    browser_normalized: PropTypes.string,
    os_normalized: PropTypes.string,
    duration: PropTypes.number,
    retryCount: PropTypes.number
  }).isRequired,
  detailLink: PropTypes.string
};

export default FunctionalUIItemDetails;
