import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionHeader, AccordionBody, Typography } from '@material-tailwind/react';
import { Title, Card } from '@tremor/react';

import SeverityChip from './SeverityChip';
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import Pagination from './Pagination';

const IssuesList = ({ title, issues }) => {
  const [open, setOpen] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [issuesPerPage, setIssuesPerPage] = useState(10); // For items per page

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  // Calculate total pages
  const totalPages = Math.ceil(issues.length / issuesPerPage);

  // Get current issues for the page
  const indexOfLastIssue = currentPage * issuesPerPage;
  const indexOfFirstIssue = indexOfLastIssue - issuesPerPage;
  const currentIssues = issues.slice(indexOfFirstIssue, indexOfLastIssue);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle change in issues per page
  const handleIssuesPerPageChange = (itemsPerPage) => {
    setIssuesPerPage(itemsPerPage);
    setCurrentPage(1); // Reset to page 1 when changing items per page
  };

  return (
    <Card className='mt-6'>
      {title && <Typography variant='h3' data-testid="issuelist-title">{title}</Typography>}

      {issues && issues.length > 0 ? (
        <>
       {currentIssues.map((issue, index) => (
            <Accordion key={issue.key} open={open === index + 1}>
              <AccordionHeader className="text-base font-normal" onClick={() => handleOpen(index + 1)}>
                <div className="grid items-center w-full" style={{ gridTemplateColumns: '1fr auto auto' }}>
                  <Typography variant='lead' className="pl-3 flex-grow border-r border-gray-300" style={{ whiteSpace: 'normal' }}>
                    {issue.message}
                  </Typography>
                  <div className="flex justify-end pl-3 pr-3 mr-3">
                    <SeverityChip severity={issue.severity} />
                  </div>
                  <div className="border-l border-gray-300">
                    {open === index + 1 ? <MdExpandLess size={35} className='ml-3' /> : <MdExpandMore size={35} className='ml-3' />}
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className='p-3'>
                <Typography variant='paragraph'><strong>File:</strong> {issue.path}</Typography>
                <Typography variant='paragraph'><strong>Line number:</strong> {issue.line}</Typography>
                <Typography variant='paragraph'><strong>Effort:</strong> {issue.effort}</Typography>
              </AccordionBody>
            </Accordion>
        ))}
        
        {issues.length > 10 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            itemsPerPage={issuesPerPage}
            onItemsPerPageChange={handleIssuesPerPageChange}
            showItemsPerPageDropdown={true}
          />
        )}

        </>
      ) : (
        <div className="items-center w-full p-2 align-center">No issues to display</div>
      )}

    </Card>
  );
};

IssuesList.propTypes = {
  title: PropTypes.string,
  issues: PropTypes.array.isRequired
};

export default IssuesList;