import React from "react";
import { Typography, Chip } from "@material-tailwind/react";
import PropTypes from 'prop-types';
import PopStaticCode from "../shared/popupExplanations/PopStaticCode";
import StaticCodeMetrics from "./StaticCodeMetrics";
import StaticCodeIssues from "./StaticCodeIssues";

import TrendChart from "../shared/TrendChart";

import { MdOutlineTimer } from "react-icons/md";
import { getTimeDifference } from "../../../datahooks/metadata/DataUtils";

export default function StaticCodeTestType({ resultData, projectId }) {

  const barKeys = [
    { dataKey: "passed", name: "Bugs" }
  ];

  return (
    <>
      <div className="flex flex-row items-center mb-5 pb-2 border-b border-gray-400 gap-2">
        <div className="w-full mb-1 flex flex-row items-center">
          <Typography variant="h2" className="text-blue mr-1">Static Code Analysis</Typography>
          <PopStaticCode page size={25} />
        </div>
        <Chip color="blue" value={getTimeDifference(resultData?.creation, resultData.last_update)} variant="outlined" icon={<MdOutlineTimer size={20} />} />
        <Chip color="blue" value={resultData?.env} />
      </div>

      <TrendChart
        projectId={projectId}
        metricType="multiple"
        testTypeId={1}
        barKeys= {barKeys}
        yAxisDomain={["auto","auto"]}
      />

      <StaticCodeMetrics metricData={resultData.result_json} />

      <StaticCodeIssues issuesData={resultData.result_json.issues} />
    </>
  );
}

StaticCodeTestType.propTypes = {
  resultData: PropTypes.object,
  projectId: PropTypes.number,
};