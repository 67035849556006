import React from 'react';
import PropTypes from 'prop-types';
import { Card } from "@tremor/react";
import { Typography } from '@material-tailwind/react';

import Overview_SCA from './Overview_SCA';
import Overview_API from './Overview_API';
import Overview_ACC from './Overview_ACC';
import Overview_FUI from './Overview_FUI';
import Overview_PER from './Overview_PER';

import OverviewCard from './OverviewCard';

import FeatureIcon from '../../general/FeatureIcon';
import { featureDetails } from '../../../datahooks/metadata/DataUtils'

import { CARD_TYPES, ErrorCard } from '../ErrorCard';



const OVERVIEW_COMPONENTS = {
    1: Overview_SCA,
    2: Overview_API,
    3: Overview_FUI,
    4: Overview_PER,
    5: Overview_ACC
};

const DefaultComponent = ({ testTypeId }) => <Card className="flex flex-col justify-center items-center h-full sm:p-3">Test type {testTypeId} is not yet supported.</Card>;

export default function OverviewCardWrapper ({ testTypeId, overviewData}) { 
    const OverviewComponent = OVERVIEW_COMPONENTS[testTypeId] || DefaultComponent;
    
    if (OverviewComponent === DefaultComponent) {
        return <OverviewComponent testTypeId={testTypeId} />;
    }

    let content;
    let displayMessage;
    if (overviewData.status) {
        switch (overviewData.status) {
            case 'pending':
            case 'ready_to_collect':
                displayMessage = ( 
                    <>
                        <ErrorCard cardType={CARD_TYPES.RUNNING} />
                        <Typography variant='paragraph' className='mt-5 text-center'>This test type is currently running.</Typography>
                        <Typography variant='paragraph' className='text-center'>Results will be available once complete.</Typography>
                    </>
                );
                break;
            case 'error':
                displayMessage = (
                    <>
                        <ErrorCard cardType={CARD_TYPES.ERROR} />
                        <Typography variant='paragraph' className='mt-5 text-center'>There has been an error while running this test type.</Typography>
                    </>
                ) 
                break;
            default:
                displayMessage = (
                        <>
                            <ErrorCard cardType={CARD_TYPES.EMPTY} />
                            <div className='m-2'>
                                <Typography variant='paragraph' className='mt-5 text-center'>There is currently no data for this test type.</Typography>
                                <Typography variant='paragraph' className='text-center'>The test type may not have been run for this release.</Typography>
                            </div>
                        </>
                    )
        }
        content = <OverviewCard
                    Icon={<FeatureIcon testTypeId={testTypeId} />}
                    title={featureDetails[testTypeId].title}
                    displayMessage={displayMessage}
                    PopoverComponent={featureDetails[testTypeId].popover}
                    onClick={() => doChangeTab(featureDetails[testTypeId].title)}
                />
    } else {

        content = <OverviewComponent icon={<FeatureIcon testTypeId={testTypeId} />}
                                title={featureDetails[testTypeId].title}
                                PopoverComponent={featureDetails[testTypeId].popover}
                                overviewData={overviewData} />;
    }

    return content;
};

OverviewCardWrapper.propTypes = {
    testTypeId: PropTypes.number,
    overviewData: PropTypes.object
};

DefaultComponent.propTypes = {
    testTypeId: PropTypes.number
};