import React from 'react';
import { DonutChart } from "@tremor/react";
import PropTypes from 'prop-types';
import LoadingIcon from "../../navigation/LoadingIcon";

const convertRatingToLetter = (rating, bestValue = 1, worstValue = 5) => {
    // Create an array of letters based on the number of levels between best and worst
    const levels = worstValue - bestValue + 1;
    const letters = Array.from({ length: levels }, (_, i) => String.fromCharCode(65 + i));
    const index = rating - bestValue;
    
    if (index < 0 || index >= letters.length) {
      console.error('Rating is out of bounds');
      return '?'; // Indicate an error or out of bounds rating
    }

    return letters[index];
  };

  const RatingDonut = ({ rating, bestValue = 1, worstValue = 5, useLetters = true, fixedHeight, fixedWidth, fontSize = '2rem' }) => {
    // Convert the rating to a numeric value.
    const numericRating = Math.max(Math.round(rating), 1);

    // Create an array for the segments with the good segments set to 1 and the bad to 0.
    const numberOfGood = (bestValue < worstValue)
      ? (worstValue - numericRating) + 1  // Lower numbers are better
      : (numericRating - worstValue) + 1; // Higher numbers are better

    const ratingData = Array.from({ length: Math.abs(worstValue - bestValue) + 1 }, (_, index) => ({
      name: `Rating ${index + 1}`,
      value: 1
    }));

    // Create an array of colors where 'good' segments are green and 'bad' segments are pink.
    const colors = ratingData.map((_, index) => 
      index < numberOfGood ? "green" : "pink"
    );

    // Determine the display value (letter or number).
    const displayValue = useLetters
      ? convertRatingToLetter(numericRating, bestValue, worstValue)
      : rating.toString();

    const style = {
      color: 'text-blue-500'
    };
    if (fixedHeight) {
      style.height = `${fixedHeight}px`;
    }
    if (fixedWidth) {
      style.width = `${fixedWidth}px`;
    }
    if (fixedHeight && fixedWidth) {
      style.marginTop = '0px';
    }

    return (
      numericRating ? (
        <DonutChart
          data={ratingData}
          category="value"
          index="name"
          colors={colors}
          variant="donut"
          label={displayValue}
          showLabel={true}
          showTooltip={false}
          showAnimation
          style={{ ...style, fontSize }}
        />
      ) : (
        <LoadingIcon showText />
      )
    );

  };

  RatingDonut.propTypes = {
    rating: PropTypes.number,
    bestValue: PropTypes.number,
    worstValue: PropTypes.number,
    useLetters: PropTypes.bool,
    fixedHeight: PropTypes.string,
    fixedWidth: PropTypes.string,
    fontSize: PropTypes.string
  };

  export default RatingDonut;