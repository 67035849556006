import React from 'react';
import PropTypes from 'prop-types';
import { Card, Metric, Grid } from '@tremor/react';
import { Typography, Chip } from '@material-tailwind/react';

import LoadingIcon from '../../navigation/LoadingIcon';
import AccessibilityTests from './AccessibilityTests';
import PopAccessibility from '../shared/popupExplanations/PopAccessibility';

import {  MdOutlineTimer } from 'react-icons/md';
import { getTimeDifference } from '../../../datahooks/metadata/DataUtils';

import TrendChart from "../shared/TrendChart";

export default function Accessibility({ resultData, projectId }) {
    const accessibilityData = resultData.result_json;
    const decorationColor =  (accessibilityData?.totalViolationInstances === 0 ? "green" : "pink");

    const barKeys = [
    { dataKey: "passed", name: "Passed Tests" },
    { dataKey: "failed", name: "Failed Tests" },
    ];    

    return (
        <div className="flex flex-col w-full">
            <div className='flex flex-row items-center mb-5 pb-2 border-b border-gray-400 gap-2'>
                <div className="w-full mb-1 flex flex-row items-center">
                    <Typography variant="h2" className="mr-1">Accessibility</Typography>
                    <PopAccessibility page size={25} />
                </div>
                <Chip color="blue" value={getTimeDifference(resultData?.creation, resultData.last_update)} variant="outlined" icon={<MdOutlineTimer size={20}/>} />
                <Chip color="blue" value={resultData?.env} />
            </div>

            <TrendChart
                projectId={projectId}
                metricType="percentage"
                yAxisDomain={[0, 100]}
                testTypeId={5}
                barKeys= {barKeys}
            />

            <Grid numItemsMd={2} numItemsLg={3} className="gap-6 mb-5">
                <div className="flex flex-col gap-4 ">
                    {accessibilityData ?  (
                        <Card className="flex w-full h-full flex-col">
                            <Typography variant='h3'>Total Pages</Typography>
                            <Metric>{accessibilityData?.total}</Metric>
                        </Card>
                    ) : (
                    <Card className="flex flex-col justify-center items-center h-full">
                        <LoadingIcon showText />
                    </Card>
                    )}
                </div>

                <div className="flex flex-col gap-4 ">
                    {accessibilityData ?  (
                        <Card className="flex w-full h-full flex-col">
                            <Typography variant='h3'>Total Tested</Typography>
                            <Metric>{accessibilityData?.totalPassInstances + accessibilityData?.totalViolationInstances}</Metric>
                        </Card>
                    ) : (
                    <Card className="flex flex-col justify-center items-center h-full">
                        <LoadingIcon showText />
                    </Card>
                    )}
                </div>

                <div className="flex flex-col gap-4 ">
                    {accessibilityData ?  (
                        <Card className="flex w-full h-full flex-col" decoration="top" decorationColor={decorationColor}>
                            <Typography variant='h3'>Total Violations</Typography>
                            <Metric>{accessibilityData?.totalViolationInstances}</Metric>
                        </Card>
                    ) : (
                    <Card className="flex flex-col justify-center items-center h-full">
                        <LoadingIcon showText />
                    </Card>
                    )}
                </div>

            </Grid>

            <AccessibilityTests title="Tests" tests={accessibilityData?.items} />

        </div>
    );
}

Accessibility.propTypes = {
    resultData: PropTypes.shape({
        result_json: PropTypes.object,
        creation: PropTypes.string,
        last_update: PropTypes.string,
        env: PropTypes.string,
    }),
    projectId: PropTypes.number,
};