import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Card } from "@tremor/react";
import { Typography } from "@material-tailwind/react";

const PerformanceHistogram = ({ theData, theIndex, title }) => {

  // Get all unique categories from the data
  const categories = theData.reduce((acc, item) => {
    Object.keys(item).forEach(key => {
      if (key !== theIndex && !acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, []);

  // Preprocess theData to ensure all categories are present in every object
  const processedData = theData.map(item => {
    const newItem = { ...item };
    categories.forEach(category => {
      if (!(category in newItem)) {
        newItem[category] = 0; // Fill missing category with 0 to ensure all categories are displayed
      }
    });
    return newItem;
  });

  return (
    <Card>
      <Typography variant="h3">{title}</Typography>
      <BarChart
        className="mt-6"
        data={processedData}
        index={theIndex}
        categories={categories}
        stack
        colors={['blue', 'yellow', 'pink', 'orange', 'green', 'cyan', 'gray']}
      />
    </Card>
  );
};

PerformanceHistogram.propTypes = {
  theData: PropTypes.array,
  theIndex: PropTypes.string,
  title: PropTypes.string
};

export default PerformanceHistogram;